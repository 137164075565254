import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAddressValidation, LazyAlertAddressValidation, LazyDeviceUnlockMuster, LazyDialogFeedbackDeposit, LazyDialogFeedbackPreorder, LazyDialogFeedbackPreorder81, LazyDialogFeedbackPreorderGsm, LazyAttachmentFileInput, LazyDeliveryStatus, LazyLayoutNotification, LazyUtilsValidatedField, LazyFormCallback, LazyFormContact, LazyFormReturn, LazyInfoContact, LazyInfoContactAddress, LazyInfoDeposit, LazyInfoDepositTransfer, LazyInfoDevice, LazyInfoDeviceCredentials, LazyInfoOrder, LazyInfoReason } from '#components'
const lazyGlobalComponents = [
  ["AddressValidation", LazyAddressValidation],
["AlertAddressValidation", LazyAlertAddressValidation],
["DeviceUnlockMuster", LazyDeviceUnlockMuster],
["DialogFeedbackDeposit", LazyDialogFeedbackDeposit],
["DialogFeedbackPreorder", LazyDialogFeedbackPreorder],
["DialogFeedbackPreorder81", LazyDialogFeedbackPreorder81],
["DialogFeedbackPreorderGsm", LazyDialogFeedbackPreorderGsm],
["AttachmentFileInput", LazyAttachmentFileInput],
["DeliveryStatus", LazyDeliveryStatus],
["LayoutNotification", LazyLayoutNotification],
["UtilsValidatedField", LazyUtilsValidatedField],
["FormCallback", LazyFormCallback],
["FormContact", LazyFormContact],
["FormReturn", LazyFormReturn],
["InfoContact", LazyInfoContact],
["InfoContactAddress", LazyInfoContactAddress],
["InfoDeposit", LazyInfoDeposit],
["InfoDepositTransfer", LazyInfoDepositTransfer],
["InfoDevice", LazyInfoDevice],
["InfoDeviceCredentials", LazyInfoDeviceCredentials],
["InfoOrder", LazyInfoOrder],
["InfoReason", LazyInfoReason],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
