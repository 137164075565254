
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as contact19XaKn8Ayvu42ITdEL3z9OXf71aqOwE7iqClP3p4KHUMeta } from "/app/pages/form/contact.vue?macro=true";
import { default as contactIframe7w0E7Ex1fZ2fAF2n528FjlFl5JSahIk2UznUt1plj8YMeta } from "/app/pages/form/contactIframe.vue?macro=true";
import { default as deposit_45feedbackRNL2upgEpjUH09GZ4U7tfJ948MqgX6GwLjW1Gk2AIqgMeta } from "/app/pages/form/deposit-feedback.vue?macro=true";
import { default as returnS0J8FlKZYuKvJdhiu0pBWbsRuGQim7WkObLQkGcpzAUMeta } from "/app/pages/form/return.vue?macro=true";
import { default as sp8_45feedback_452LHud70zb5xmoEAS6RoayARN2YE6PcJb4DUUYPfiKsVsMeta } from "/app/pages/form/sp8-feedback-2.vue?macro=true";
import { default as sp8_45feedback_453QUEN2D0fysgEmgE_452oCYXYUBj09a6Ij3g9s_45Ygq9gBQMeta } from "/app/pages/form/sp8-feedback-3.vue?macro=true";
import { default as sp81_45feedbackLVCcam2AK6X0GSF7Fhd33_hfWetSiF_45rx1FyTdAhEowMeta } from "/app/pages/form/sp81-feedback.vue?macro=true";
import { default as statusGxyJAiT6T08i4QnaacTYMdHkpZ5UFlYXwaNfFcltlQwMeta } from "/app/pages/status.vue?macro=true";
export default [
  {
    name: "form-contact",
    path: "/form/contact",
    meta: contact19XaKn8Ayvu42ITdEL3z9OXf71aqOwE7iqClP3p4KHUMeta || {},
    component: () => import("/app/pages/form/contact.vue")
  },
  {
    name: "form-contactIframe",
    path: "/form/contactIframe",
    meta: contactIframe7w0E7Ex1fZ2fAF2n528FjlFl5JSahIk2UznUt1plj8YMeta || {},
    component: () => import("/app/pages/form/contactIframe.vue")
  },
  {
    name: "form-deposit-feedback",
    path: "/form/deposit-feedback",
    meta: deposit_45feedbackRNL2upgEpjUH09GZ4U7tfJ948MqgX6GwLjW1Gk2AIqgMeta || {},
    component: () => import("/app/pages/form/deposit-feedback.vue")
  },
  {
    name: "form-return",
    path: "/form/return",
    meta: returnS0J8FlKZYuKvJdhiu0pBWbsRuGQim7WkObLQkGcpzAUMeta || {},
    component: () => import("/app/pages/form/return.vue")
  },
  {
    name: "form-sp8-feedback-2",
    path: "/form/sp8-feedback-2",
    meta: sp8_45feedback_452LHud70zb5xmoEAS6RoayARN2YE6PcJb4DUUYPfiKsVsMeta || {},
    component: () => import("/app/pages/form/sp8-feedback-2.vue")
  },
  {
    name: "form-sp8-feedback-3",
    path: "/form/sp8-feedback-3",
    meta: sp8_45feedback_453QUEN2D0fysgEmgE_452oCYXYUBj09a6Ij3g9s_45Ygq9gBQMeta || {},
    component: () => import("/app/pages/form/sp8-feedback-3.vue")
  },
  {
    name: "form-sp81-feedback",
    path: "/form/sp81-feedback",
    meta: sp81_45feedbackLVCcam2AK6X0GSF7Fhd33_hfWetSiF_45rx1FyTdAhEowMeta || {},
    component: () => import("/app/pages/form/sp81-feedback.vue")
  },
  {
    name: "status",
    path: "/status",
    meta: statusGxyJAiT6T08i4QnaacTYMdHkpZ5UFlYXwaNfFcltlQwMeta || {},
    component: () => import("/app/pages/status.vue")
  }
]